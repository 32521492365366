import React from "react";
import "../src/styles/styles.css";
import Header from "./components/Header";
import MainContainer from "./components/MainContainer";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <div className="wrapper">
        <Header />
        <MainContainer />
      </div>
    </Router>
  );
};

export default App;
