import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faJsSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faMobileAlt,
  faCogs,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";

const TechStack = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        <div className="row">
          <div className="">
            <div className="about-box">
              <div className="about-me">
                {/* <h6></h6> */}
                <h2>
                  Tech<span className="theme-color">&nbsp;Stack</span>
                </h2>
                <p>
                  I'm a Software Engineer with <b>20 years</b> of experience. I
                  have been architecting and implementing software solutions{" "}
                  <b>half of my life</b>.
                </p>
                <p>
                  One of my greatest joys is <b>building</b> things. So I'm{" "}
                  <b>passionate</b> about producing{" "}
                  <b>efficient, appealing and robust</b> digital solutions. I am
                  an <b>avid</b> technical reader and learner.
                </p>
                <p>
                  I find it <b>naturally rewarding</b> to create web apps that
                  make life easier <b>for others</b> and <b>solve</b> problems.
                </p>
                <p>
                  I strive to be a top-tier developer. So I'm always improving
                  my <b>communication skills</b>. <b>Learning</b> has been and
                  is my life-style. All boiled down to natural curiosity.
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-box">
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faCogs}
                    />
                    <div className="feature-content">
                      <h5>Back-End</h5>
                      <p>
                        As a Microsoft Certified Developer, I've been focused on
                        Microsoft <b>.NET Framework</b> and{" "}
                        <b>.NET Framework Core</b> with&nbsp;
                        <b>C#</b>. <b>RESTful-API</b> is my favorite
                        architectural pattern but I'm flexible enough to develop
                        Component-based or Monolithic applications when
                        necessary.
                      </p>
                      <p>
                        Also I have developed and implemented rest services with{" "}
                        <b>Nodejs</b> with <b>Express</b>.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box">
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faJsSquare}
                    />
                    <div className="feature-content">
                      <h5>Front-End</h5>
                      <p>
                        I love the flexibility of latest JS
                        Frameworks/libraries. I've been focused on{" "}
                        <b>Reactjs</b>&nbsp; during the lastest 5 years.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box">
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faMobileAlt}
                    />
                    <div className="feature-content">
                      <h5>Mobile Developer</h5>
                      <p>
                        I've put in production applications using Ionic. But
                        most of my apps were developed using <b>React Native</b>
                        . I've passed through the process of publishing hybrid
                        apps in both <b>Google Play</b> and <b>Apple</b> stores
                        several times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box">
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faDatabase}
                    />
                    <div className="feature-content">
                      <h5>Data Stores</h5>
                      <p>
                        I can architect normalized relational databases using{" "}
                        <b>MS SQL Server</b> and <b>MySQL</b>.
                      </p>
                      <p>
                        I have experience with No-SQL databases. Particularly{" "}
                        <b>MongoDB</b> and <b>Firebase</b>.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box">
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faUsersCog}
                    />
                    <div className="feature-content">
                      <h5>Tech Lead</h5>
                      <p>
                        I've been in charge of fellow developers for the latest
                        10 years. I'm used to being responsible for the
                        technical decisions that will impact a business and take
                        care of people as well as mentoring junior developers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-bar">
                {/* <a href="#portfolio" className="m-btn m-btn-theme">
                  some of my works
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechStack;
