import React, { useRef } from "react";
import useOnScreen from "../customHooks/useOnScreen";

const sendEmail = () => {
  window.open("mailto:ronny@ronnydelgado.com");
};

const Contact = () => {
  const contactTitleRef = useRef();
  const isVisible = useOnScreen(contactTitleRef);

  return (
    <section id="contact" className="home-banner">
      <div className="container">
        <div className="row">
          {/* <div className=""> */}
          <div
            className="about-box"
            style={{ paddingLeft: "65px", paddingTop: "30px" }}
          >
            <div className="about-me">
              {/* <h6></h6> */}

              <h2
                ref={contactTitleRef}
                className={isVisible ? "animate__animated animate__heartBeat" : ""}
              >
                Contact<span className="theme-color">&nbsp;Me</span>
              </h2>

              {/* <p>Do you or your business need help with web presence?</p>
              <p>Do you need extra help for you next gig?</p> */}
              <p>Let's chat!</p>
            </div>

            {/* <div className="row">
                <div className="col-md-6">
                  <div className="feature-box">
                    
                  </div>
                </div>
              </div> */}
            <div className="btn-bar">
              <button onClick={sendEmail} className="m-btn m-btn-theme">
                Send Email
              </button>
            </div>
            <div className="margin-bottom-40"></div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
