import React from 'react';
import bgImage from '../assets/images/1600x1000.jpg';

const CounterSplit = () => {

    return (
        <section className="counter-section bg-center-center bg-cover bg-fixed home-bg" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="container">
                <div className="counter-row">
                    <div className="row">
                        <div className="col-6 col-md-3 col-sm-6">
                            <div className="counter-col">
                                <div className="counter-data" data-count="375">
                                    <div className="count"></div>
                                    {/* <h6></h6> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-md-3 col-sm-6">
                            <div className="counter-col">
                                <div className="counter-data" data-count="375">
                                    <div className="count"></div>
                                    {/* <h6></h6> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-md-3 col-sm-6">
                            <div className="counter-col">
                                <div className="counter-data" data-count="550">
                                    <div className="count"></div>
                                    {/* <h6></h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-sm-6">
                            <div className="counter-col">
                                <div className="counter-data" data-count="450">
                                    <div className="count"></div>
                                    {/* <h6></h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterSplit;