import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const getYear = new Date().getFullYear();

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <ul className="social-icons">
          <li>
            <a
              href="https://www.linkedin.com/in/ronnydelgado/"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/iamrondelgado"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/ronny_delgado_"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ronnydelgado_/"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>
        <p className="copyright">
          © {getYear} Ronny Delgado. All Rights Reserved
        </p>
        <p className="small">
          I developed this site using <b>Reactjs</b>. It's hosted in <b>AWS</b>.
          CI/CD powered by <b>AWS Amplify</b> and <b>GitHub</b>.
        </p>
      </div>
    </section>
  );
};

export default Footer;
