import React, { useRef } from "react";
import useOnScreen from "../customHooks/useOnScreen";
import Card from "react-bootstrap/Card";

const Recommendations = () => {
  const recommend1 = useRef();
  const recommend2 = useRef();
  const recommend3 = useRef();
  const visibleReco1 = useOnScreen(recommend1);
  const visibleReco2 = useOnScreen(recommend2);
  const visibleReco3 = useOnScreen(recommend3);

  return (
    <section id="recommendations" className="section">
      <div className="container">
        <div className="row">
          <div className="">
            <div className="about-box">
              <div className="about-me">
                <h2>
                  Some<span className="theme-color">&nbsp;Recommendations</span>
                </h2>
              </div>
              {/* <div className="row">
                  <div
                    className="container"
                  > */}

              <Card
                ref={recommend1}
                className={
                  visibleReco1 ? "animate__animated animate__zoomIn" : ""
                }
              >
                <Card.Header></Card.Header>
                <Card.Body>
                  <div>
                    <p>
                      <i>
                        Ronny is a <b>dedicated</b> professional with a strong
                        desire to learn and sharpen his skills. I couldn't ask
                        for a <b>better foundation</b> in any developer I work
                        with. Ronny is <b>loyal</b>, <b>hard working</b>, and
                        dedicated to his craft. He loves what he does, which is
                        a <b>rare commodity</b> in the coding profession.{" "}
                        <b>A reliable and skilled asset</b> to any on prem or
                        remote development team.
                      </i>
                    </p>
                    <footer className="blockquote-footer">
                      <a
                        href="https://www.linkedin.com/in/ckirby/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Chris Kirby
                      </a>{" "}
                      <cite title="">SVP, Engineering at StockX</cite>
                    </footer>
                  </div>
                </Card.Body>
              </Card>

              <div className="margin-top-10" />

              <Card
                ref={recommend2}
                className={
                  visibleReco2 ? "animate__animated animate__zoomIn" : ""
                }
              >
                <Card.Header></Card.Header>
                <Card.Body>
                  <div>
                    <p>
                      <i>
                        I had the privilege of <b>managing Ronny</b> in his
                        position as Senior Web Developer. Ronny was a{" "}
                        <b>joy to work with</b>. He has an{" "}
                        <b>exceptional attitude</b>, works well with both his
                        peers and management. Ronny has excellent technical
                        skills and is an <b>efficient</b> developer. He knows
                        how to <b>balance</b> doing what he is asked to do and{" "}
                        <b>tactfully adding great suggestions</b> when he
                        notices a better solution might be appropriate. I think
                        Ronny <b>is in the top</b> of his class at pretty much
                        everything he does. I would very much enjoy working with
                        him again someday.
                      </i>
                    </p>
                    <footer className="blockquote-footer">
                      <a
                        href="https://www.linkedin.com/in/conrad-brandt-92ba7024/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Conrad Brandt
                      </a>{" "}
                      <cite title=""></cite>
                    </footer>
                  </div>
                </Card.Body>
              </Card>

              <div className="margin-top-10" />

              <Card
                ref={recommend3}
                className={
                  visibleReco3 ? "animate__animated animate__zoomIn" : ""
                }
              >
                <Card.Header></Card.Header>
                <Card.Body>
                  <div>
                    <p>
                      <i>
                        Ronny is one of those <b>“hard to find”</b> talented
                        developers. From a technical standpoint Ronny is a{" "}
                        <b>real professional</b>, plenty of <b>skills</b> and{" "}
                        <b>capabilities</b>. He has shown to be always at the{" "}
                        <b>outermost edge</b> of the latest technologies{" "}
                        <b>sharing</b> his knowledge. Not to mention his most
                        valuable part (to me at least): a <b>great teammate</b>{" "}
                        with a strong background always <b>willing to help</b>{" "}
                        others. I really enjoyed working with you Ronny!!!.
                      </i>
                    </p>
                    <footer className="blockquote-footer">
                      <a
                        href="https://www.linkedin.com/in/claudioromero/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Claudio Romero
                      </a>{" "}
                      <cite title="">Blockchain Engineer, Digifox</cite>
                    </footer>
                  </div>
                </Card.Body>
              </Card>

              {/* <div style={{ paddingTop: "20px" }}>
                    <h4>
                      <i>
                        Ronny is a dedicated professional with a strong desire
                        to learn and sharpen his skills. I couldn't ask for a
                        better foundation in any developer I work with. Ronny is
                        loyal, hard working, and dedicated to his craft. He
                        loves what he does, which is a rare commodity in the
                        coding profession. A reliable and skilled asset to any
                        on prem or remote development team.
                      </i>
                    </h4>
                    <a
                      href="https://www.linkedin.com/in/ckirby/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chris Kirby
                    </a>
                    <p>VP, Engineering at StockX</p>
                  </div> */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <h2>
        Some<span className="theme-color">&nbsp;Recomendations</span>
      </h2>
      <div className="container" style={{ backgroundColor: "#C8CED7" }}>
        <div style={{ paddingTop: "40px" }}>
          <h3 className="blockquote">
            <i>
              Ronny is a dedicated professional with a strong desire to learn
              and sharpen his skills. I couldn't ask for a better foundation in
              any developer I work with. Ronny is loyal, hard working, and
              dedicated to his craft. He loves what he does, which is a rare
              commodity in the coding profession. A reliable and skilled asset
              to any on prem or remote development team.
            </i>
          </h3>
          <a
            href="https://www.linkedin.com/in/ckirby/"
            target="_blank"
            rel="noreferrer"
          >
            Chris Kirby
          </a>
          <p>VP, Engineering at StockX</p>
        </div>
      </div> */}
    </section>
  );
};

export default Recommendations;
