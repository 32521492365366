import React from "react";
import HomeBanner from "./HomeBanner";
import Values from "./Values";
import TechStack from "./TechStack";
//import Resume from "./Resume";
import CounterSplit from "./CounterSplit";
//import Skills from "./Skills";
import Contact from "./Contact";
import Recommendations from "./Recommendations";
import Footer from "./Footer";

const MainContainer = () => {
  return (
    <div>
      <HomeBanner />
      <Values />
      <Recommendations />
      <CounterSplit/>
      <TechStack />
      {/*<CounterSplit />
       <Resume />
      <Skills /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default MainContainer;
