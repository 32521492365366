import React, { useRef } from "react";
import useOnScreen from "../customHooks/useOnScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsHelping,
  faHandPaper,
  faHardHat,
  faRandom,
  faHammer,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";

const Values = () => {
  const featureBox1 = useRef();
  const featureBox2 = useRef();
  const featureBox3 = useRef();
  const featureBox4 = useRef();
  const featureBox5 = useRef();
  const featureBox6 = useRef();
  const visibleFeatureBox1 = useOnScreen(featureBox1);
  const visibleFeatureBox2 = useOnScreen(featureBox2);
  const visibleFeatureBox3 = useOnScreen(featureBox3);
  const visibleFeatureBox4 = useOnScreen(featureBox4);
  const visibleFeatureBox5 = useOnScreen(featureBox5);
  const visibleFeatureBox6 = useOnScreen(featureBox6);

  return (
    <section id="values" className="section gray-bg">
      <div className="container">
        <div className="row">
          <div className="">
            <div className="about-box">
              <div className="about-me">
                <h6>More on tech later, first...</h6>
                <h2>
                  My<span className="theme-color">&nbsp;Values</span>
                </h2>
                <p>
                  Technologies, tools, frameworks and methodologies come and go.
                </p>
                <p>
                  Values, on the other hand, define a professional and they will
                  always be there.
                </p>
                <p>
                  The foundation of any new knowledge, education or expertise I
                  have acquired are:
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox1
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox1}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faHandsHelping}
                    />
                    <div className="feature-content">
                      <h5>Humility</h5>
                      <p>
                        It's essential to recognize that I don't know
                        everything. Fellow developers, customers, and all parts
                        interested in a project have their own competences and
                        these will add value to a project.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox2
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox2}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faHandPaper}
                    />
                    <div className="feature-content">
                      <h5>Ownership</h5>
                      <p>
                        There is no use in "ain’t my problem" kind of attitude
                        and that’s not healthy to have in any project. I care
                        what I do. I welcome responsibility gladly. I don't need
                        much direction to do what I have to do.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox3
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox3}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faHardHat}
                    />
                    <div className="feature-content">
                      <h5>Diligence</h5>
                      <p>
                        Diligence brings motivation, discipline and
                        concentration to get the best from my time =
                        Productivity. Diligence makes me run toward the fire and
                        don't hide issues.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox4
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox4}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faRandom}
                    />
                    <div className="feature-content">
                      <h5>Flexibility</h5>
                      <p>
                        The ability to adaptation helps me to survive in a world
                        where the only constant is the change. Also, it allows
                        me to keep an open mind in relation to customer needs,
                        architecting, or utilizing the latest and greatest in
                        technology.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox5
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox5}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faHammer}
                    />
                    <div className="feature-content">
                      <h5>Tenacity</h5>
                      <p>
                        Building software is hard. It's a discipline. Our
                        patience and willpower are tested constantly. Not giving
                        up is one of the most useful qualities I've grown in my
                        career.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      visibleFeatureBox6
                        ? "feature-box animate__animated animate__slideInUp"
                        : "feature-box"
                    }
                    ref={featureBox6}
                  >
                    <FontAwesomeIcon
                      className="icon theme-bg ti-layout"
                      icon={faBrain}
                    />
                    <div className="feature-content">
                      <h5>Honest Curiosity</h5>
                      <p>
                        Curiosity is the reason I studied CS. Since childhood
                        I've wanted to know why certain things work the way they
                        do. Back in '96 I wanted to know why Clippy was able to
                        "guess" what I wanted to write in MS Word, so with
                        honest curiosity I started to learn programming. Towards
                        the end of '99 I found my first job as a Junior
                        Developer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-bar">
                {/* <a href="#portfolio" className="m-btn m-btn-theme">some of my works</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
